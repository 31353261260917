<template>
    <div class="wrap-dashboard">
        <div class="wrap-content">
            <div class="top-menu">
                <div class="filter">
                    <dropdown-directive position="left" tryProps="">
                        <template v-slot:buttonDropdown>
                            <button class="btn-filter">Sort by Date: {{ form.startDate + " - " + form.endDate }}</button>
                        </template>
                        <template v-slot:menuDropdown>
                            <div class="filter-dropdown">
                                <input
                                    placeholder="Choose Start Date"
                                    type="text"
                                    onfocus="(this.type='date')"
                                    onblur="(this.type='text')"
                                    id="start-date"
                                    :max="today"
                                    class="form-input"
                                    v-model="form.startDate"
                                />
                                <input
                                    placeholder="Choose End Date"
                                    type="text"
                                    onfocus="(this.type='date')"
                                    onblur="(this.type='text')"
                                    id="end-date"
                                    class="form-input"
                                    v-model="form.endDate"
                                    :min="form.startDate"
                                    :max="today"
                                    :disabled="form.startDate == ''"
                                />
                                <div class="wrap-button">
                                    <button type="button" class="btn-primer" @click="filterDate">Apply</button>
                                </div>
                            </div>
                        </template>
                    </dropdown-directive>
                    <div class="sort-pialang">
                        <select name="" id="" class="filter-select" v-model="clientType" @change="filterClienType">
                            <option value="" selected disabled>Client Type</option>
                            <option value="pc-pc">PC - PC</option>
                            <option value="mm-pc">MM - PC</option>
                            <option value="pc-mm">PC - MM</option>
                        </select>
                    </div>
                    <div class="sort-pialang">
                        <select name="" id="" class="filter-select" v-model="brokerCode" @change="filterBroker">
                            <option value="" selected disabled>Broker Code</option>
                            <option value="001">001 - ABI</option>
                        </select>
                    </div>
                    <div class="sort-bank" v-show="role != 13">
                        <select name="" id="" class="filter-select" v-model="bankCode" @change="filterBank">
                            <option value="" selected disabled>Bank Code</option>
                            <option value="037">BAGI</option>
                            <option value="022">CIMB</option>
                        </select>
                    </div>
                    <div class="wrap-dropdown">
                        <!-- <button class="btn-filter" @click="showToggleOrder = !showToggleOrder">Sort Trade Match Id {{ form.order_match_id }}</button>
                            <div class="dropdown" v-show="showToggleOrder">
                                <input type="text" class="form-input" v-model="form.order_match_id" @input="filterTradeId" placeholder="Input Trade Match Id" />
                            </div> -->
                        <input class="btn-filter filter-trade" type="text" placeholder="Sort Trade Match Id" v-model="form.order_match_id" @input="filterTradeId" />
                    </div>
                    <div class="reset-filter">
                        <button type="button" @click="resetFilter" v-show="form.endDate != '' || clientType != '' || form.order_match_id != ''">Reset Filter</button>
                    </div>
                </div>
                <div class="wrap-dropdown">
                    <ExportExcel v-show="hideExcel" :dataFilter="dataExcel" ref="exportExcelId" @setLoadData="setIsloadDataExcel" />
                    <dropdown-directive>
                        <template v-slot:buttonDropdown>
                            <button class="btn-filter">Download</button>
                        </template>
                        <template v-slot:menuDropdown>
                            <div class="filter-dropdown">
                                <div class="select-type-date">
                                    <div class="wrap-input">
                                        <input
                                            type="radio"
                                            name=""
                                            id="By Month"
                                            value="month"
                                            v-model="typeDate"
                                            @change="(formExcel.startDate = ''), (formExcel.endDate = ''), (formExcel.monthDate = '')"
                                        />
                                        <label for="By Month">By Month</label>
                                    </div>
                                    <div class="wrap-input">
                                        <input
                                            type="radio"
                                            name=""
                                            id="By Date"
                                            value="date"
                                            v-model="typeDate"
                                            @change="(formExcel.startDate = ''), (formExcel.endDate = ''), (formExcel.monthDate = '')"
                                        />
                                        <label for="By date">By Date</label>
                                    </div>
                                </div>
                                <div v-if="typeDate == 'date'">
                                    <div>
                                        <label for="start-date">Start</label>
                                        <input
                                            placeholder="Pilih tanggal awal excel"
                                            type="date"
                                            id="start-date"
                                            :max="today"
                                            class="form-input"
                                            v-model="formExcel.startDate"
                                            @change="changeMaxDate"
                                        />
                                    </div>
                                    <div>
                                        <label for="end-date">End</label>
                                        <input
                                            placeholder="Pilih tanggal akhir"
                                            type="date"
                                            id="end-date"
                                            class="form-input"
                                            v-model="formExcel.endDate"
                                            :min="formExcel.startDate"
                                            :max="maxTodayDownload"
                                        />
                                    </div>
                                </div>
                                <div v-else>
                                    <div>
                                        <label for="">Month</label>
                                        <input type="month" name="" id="" class="form-input" v-model="formExcel.monthDate" :max="todayMonth" />
                                    </div>
                                </div>
                                <button type="button" class="btn-primer" @click="tableToExcel" :disabled="formExcel.monthDate == '' && formExcel.endDate == ''">
                                    <b-spinner variant="light" v-if="isLoading" small></b-spinner>
                                    <span v-else>Apply</span>
                                </button>
                            </div>
                        </template>
                    </dropdown-directive>
                </div>
            </div>
            <div class="content">
                <!-- <div class="wrap-table"> -->
                <div class="view-perpages">
                    <select v-model="perPage" @change="showDataRow">
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                    <span>&nbsp;entries per page</span>
                </div>
                <table id="testTable1">
                    <thead>
                        <tr id="top">
                            <th scope="col" rowspan="2" style="width: 70px">No</th>
                            <th scope="col" rowspan="2" style="width: 120px">Trade Matched Id</th>
                            <th scope="col" rowspan="2">TimeStamp</th>
                            <th scope="col" rowspan="2">Rate</th>
                            <th scope="col" colspan="10">Buyer</th>
                            <th scope="col" colspan="10">Seller</th>
                        </tr>
                        <tr>
                            <th scope="col">Order Id</th>
                            <th scope="col">Trade Id</th>
                            <th scope="col">VA Code</th>
                            <th scope="col">Weight (gr)</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Fee</th>
                            <th scope="col">Tax Fee</th>
                            <th scope="col">Tax trx</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Revenue Broker</th>
                            <th scope="col">Order Id</th>
                            <th scope="col">Trade Id</th>
                            <th scope="col">VA Code</th>
                            <th scope="col">Weight (gr)</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Fee</th>
                            <th scope="col">Tax Fee</th>
                            <th scope="col">Tax trx</th>
                            <th scope="col">Total Amount</th>
                            <th scope="col">Revenue Broker</th>
                        </tr>
                    </thead>
                    <tbody v-if="loadingData">
                        <tr>
                            <td class="text-center" colspan="20">
                                <b-spinner label="Loading..."></b-spinner>
                            </td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr v-for="(data, i) in data" :key="i" v-show="data.length != 0">
                            <td data-label="No" style="border-right: 1px solid #ededed">
                                {{ (currentPage - 1) * perPage + (i + 1) }}
                            </td>
                            <td data-label="Trade Match Id" style="border-right: 1px solid #ededed">
                                {{ data._id.order_match_id }}
                            </td>
                            <td data-label="Time Stamp" style="border-right: 1px solid #ededed">
                                {{ data.timestamp | formatDateTimeReal }}
                            </td>
                            <td data-label="Rate" style="border-right: 1px solid #ededed">
                                <div v-if="data.type_client == 'PC-LP' || data.type_client == 'PC-CP'">
                                    Buy
                                    {{ data.direction[0].payment_info.rate ? " Rp" + data.direction[0].payment_info.rate.toLocaleString() : "" }}
                                </div>
                                <div v-else-if="data.type_client == 'LP-PC' || data.type_client == 'CP-PC'">
                                    Sell
                                    {{ data.direction[1].payment_info.rate ? " Rp" + data.direction[1].payment_info.rate.toLocaleString() : "" }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="Order Id" class="description">
                                {{ data.direction[0] ? data.direction[0].order_id : "-" }}
                            </td>
                            <td data-label="Remarks">
                                <div v-show="data.direction[0].tradebuy">
                                    <span v-if="role == 1 || role == 6">
                                        <img src="../assets/cimb.png" alt="" class="icon-trade" v-show="data.direction[0].payment_info.bank_code == '022'" />
                                        <img src="../assets/bagi.png" alt="" class="icon-trade" v-show="data.direction[0].payment_info.bank_code == '037'" />
                                    </span>
                                    <span v-else>
                                        {{ data.direction[0].tradebuy[1] }}
                                    </span>
                                    <span>
                                        {{ " " + data.direction[0].tradebuy[0] + "-" }}
                                    </span>
                                    <span>{{ data.direction[0].tradebuy[2] }}</span>
                                </div>
                            </td>
                            <td data-label="VA Code">
                                {{ data.direction[0] ? data.direction[0].va_code : "" }}
                            </td>
                            <td data-label="weight">
                                {{ data.direction[0].gold_info ? data.direction[0].gold_info.total_gram : "-" }}
                            </td>
                            <td data-label="Amount">
                                {{
                                    data.direction[0].payment_info.amount_idr
                                        ? "IDR " + data.direction[0].payment_info.amount_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>
                            <td data-label="fee">
                                <div v-if="data.lpbuy == 'PC'">
                                    {{
                                        data.direction[0].payment_info.fee_idr
                                            ? "IDR " + data.direction[0].payment_info.fee_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                            : "IDR -"
                                    }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="tax fee">
                                <div v-if="data.lpbuy == 'PC'">
                                    {{
                                        data.direction[0].payment_info.tax_fee_idr
                                            ? "IDR " + data.direction[0].payment_info.tax_fee_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                            : "-"
                                    }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="tax trx">
                                <div v-if="data.lpbuy == 'PC'">
                                    {{ data.direction[0].payment_info.tax }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="Total Amount">
                                {{
                                    data.direction[0].payment_info.payment_idr
                                        ? "IDR " + data.direction[0].payment_info.payment_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>
                            <td data-label="Revenue Broker" style="border-right: 1px solid #ededed">
                                {{
                                    data.direction[0].payment_info.revenue_broker
                                        ? "IDR " + data.direction[0].payment_info.revenue_broker.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>

                            <td data-label="Order Id">
                                {{ data.direction[1] ? data.direction[1].order_id : "-" }}
                            </td>
                            <td data-label="Remarks">
                                <div v-show="data.direction[1] ? data.direction[1].tradeSell : '-'">
                                    <span v-if="role == 1 || role == 6">
                                        <img
                                            src="../assets/cimb.png"
                                            alt=""
                                            class="icon-trade"
                                            v-show="data.direction[1] ? data.direction[1].payment_info.bank_code == '022' : ''"
                                        />
                                        <img
                                            src="../assets/bagi.png"
                                            alt=""
                                            class="icon-trade"
                                            v-show="data.direction[1] ? data.direction[1].payment_info.bank_code == '037' : ''"
                                        />
                                    </span>
                                    <span v-else>{{ data.direction[1].tradeSell[1] }} </span>
                                    <span>
                                        {{ data.direction[1] ? " " + data.direction[1].tradeSell[0] + "-" : "" }}
                                    </span>
                                    <span>{{ data.direction[1] ? data.direction[1].tradeSell[2] : "-" }}</span>
                                </div>
                            </td>
                            <td data-label="VA Code">
                                {{ data.direction[1] ? data.direction[1].va_code : "" }}
                            </td>
                            <td data-label="weight">
                                {{ data.direction[1].gold_info ? data.direction[1].gold_info.total_gram : "-" }}
                            </td>
                            <td data-label="Amount">
                                {{
                                    data.direction[1].payment_info.amount_idr
                                        ? "IDR " + data.direction[1].payment_info.amount_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>
                            <td data-label="fee">
                                <div v-if="data.lpsell == 'PC'">
                                    {{
                                        data.direction[1].payment_info.fee_idr
                                            ? "IDR " + data.direction[1].payment_info.fee_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                            : "IDR -"
                                    }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="Tax Fee">
                                <div v-if="data.lpsell == 'PC'">
                                    {{
                                        data.direction[1].payment_info.tax_fee_idr
                                            ? "IDR " + data.direction[1].payment_info.tax_fee_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                            : "IDR -"
                                    }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="Tax Trx">
                                <div v-if="data.lpsell == 'PC'">
                                    {{ data.direction[1].payment_info.tax }}
                                </div>
                                <div v-else>-</div>
                            </td>
                            <td data-label="Total Amount">
                                {{
                                    data.direction[1].payment_info.payment_idr
                                        ? "IDR " + data.direction[1].payment_info.payment_idr.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>
                            <td data-label="Revenue Broker">
                                {{
                                    data.direction[1].payment_info.revenue_broker
                                        ? "IDR " + data.direction[1].payment_info.revenue_broker.toLocaleString(undefined, { minimumFractionDigits: 2 })
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr v-show="data.length == 0">
                            <td colspan="20">No Data Available</td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-end mt-3">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="lengthData"
                        :per-page="perPage"
                        @change="handlePageChange"
                        aria-controls="new-table"
                        first-number
                        last-number
                        v-show="lengthData > 10"
                    ></b-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import ExportExcel from "../components/ExportExcel.vue";
import moment from "moment";
import DropdownDirective from "../components/dropdown/DropdownDirective.vue";
// import * as XLSX from "xlsx";

export default {
    components: {
        ExportExcel,
        DropdownDirective,
    },
    data() {
        return {
            dataTransaction: [],
            pageOfItems: [],
            currentPage: 1,
            perPage: 10,
            lengthData: 1,
            trade_id: [],
            offset: 0,
            data: [],
            time_stamp: "",
            loadingData: false,
            today: "",
            todayMonth: "",
            role: "",
            form: {
                startDate: "",
                endDate: "",
                order_match_id: "",
            },
            formExcel: {
                startDate: "",
                endDate: "",
                monthDate: "",
            },
            showToggleDate: false,
            showToggleOrder: false,
            toggleExcel: false,
            clientType: "",
            brokerCode: "001",
            bankCode: "",
            dataExcel: {},
            hideExcel: false,
            liveTime: null,
            currentDate: null,
            dataClient: [],
            typeDate: "month",
            isLoading: false,
            maxTodayDownload: "",
        };
    },
    mounted() {
        this.today = new Date().toISOString().split("T")[0];
        this.todayMonth = this.today.slice(0, 7);
        this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        this.role = sessionStorage.getItem("role");

        let date = new Date();
        this.currentDate = moment(date).format("dddd, DD MMMM YYYY");
    },
    watch: {
        // "formExcel.startDate"() {
        //     if (this.formExcel.startDate) {
        //         const date = new Date(this.formExcel.startDate);
        //         const month = date.getMonth();
        //         const year = date.getFullYear();
        //         const maxDate = new Date(year, month + 1, 0).getDate();
        //         this.maxTodayDownload = this.formExcel.startDate.slice(0, 7) + "-" + JSON.stringify(maxDate);
        //     }
        // },
    },
    created() {
        let like = this;
        setInterval(function () {
            let startDate = like.form.startDate;
            let endDate = like.form.endDate;
            like.getData(like.currentPage, startDate, endDate, like.clientType, like.bankCode, like.form.order_match_id);
        }, 60000);

        setInterval(() => {
            like.showLiveTime();
        }, 1000);
    },

    methods: {
        setIsloadDataExcel(event) {
            this.isLoading = event;
        },
        showLiveTime() {
            let date = new Date();
            this.liveTime = moment(date).format("HH:mm:ss");
        },
        inputToExcel() {
            var data = {
                startDate: this.formExcel.startDate,
                endDate: this.formExcel.endDate,
                bank_code: this.bankCode,
                client_type: this.clientType,
                offset: 0,
            };

            this.dataExcel = {
                startDate: this.formExcel.startDate,
                endDate: this.formExcel.endDate,
                bank_code: this.bankCode,
                client_type: this.clientType,
                offset: 0,
            };
            this.$emit("inputExcel", data);
            this.$root.$emit("inputExcel", data);
        },
        tableToExcel() {
            if (this.formExcel.monthDate != "") {
                const date = new Date(this.formExcel.monthDate);
                const month = date.getMonth();
                const year = date.getFullYear();
                const maxDate = new Date(year, month + 1, 0).getDate();
                this.formExcel.startDate = this.formExcel.monthDate + "-01";
                this.formExcel.endDate = this.formExcel.monthDate + "-" + JSON.stringify(maxDate);
            }

            this.dataExcel = {
                startDate: this.formExcel.startDate,
                endDate: this.formExcel.endDate,
                bank_code: this.bankCode,
                client_type: this.clientType,
                offset: 0,
            };

            this.$refs.exportExcelId.setDataDownload(this.dataExcel);
            // this.$refs.exportExcelId.setToDownload();
        },
        btnTableExcel() {
            this.formExcel.startDate = "";
            this.formExcel.endDate = "";
            this.toggleExcel = !this.toggleExcel;
        },

        changeMaxDate() {
            let today = new Date().toISOString().split("T")[0];
            if (this.formExcel.startDate.slice(0, 7) === today.slice(0, 7)) {
                this.maxTodayDownload = today;
            } else {
                const date = new Date(this.formExcel.startDate);
                const month = date.getMonth();
                const year = date.getFullYear();
                const maxDate = new Date(year, month + 1, 0).getDate();
                this.maxTodayDownload = this.formExcel.startDate.slice(0, 7) + "-" + JSON.stringify(maxDate);
            }

            this.formExcel.endDate = "";
        },

        toggle() {
            this.showToggleDate = !this.showToggleDate;
        },
        handlePageChange(value) {
            this.getData(value, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        filterTradeId(event) {
            this.currentPage = 1;
            if (event.target.value.length > 3) {
                this.form.order_match_id = event.target.value;
                this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
            }
        },
        filterDate() {
            this.currentPage = 1;
            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
            this.showToggleDate = false;
        },
        showDataRow() {
            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        },
        cancelFilterDate() {
            this.form.startDate = "";
            this.form.endDate = "";
            this.showToggleDate = false;
        },
        filterBroker() {
            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        },
        filterBank() {
            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        },
        filterClienType() {
            this.currentPage = 1;
            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        },
        resetFilter() {
            this.form.startDate = "";
            this.form.endDate = "";
            this.form.order_match_id = "";
            this.clientType = "";
            this.showToggleOrder = false;

            this.getData(this.currentPage, this.form.startDate, this.form.endDate, this.clientType, this.bankCode, this.form.order_match_id);
        },
        getData(value, startDate, endDate, clientType, bankCode, order_id) {
            let offset = (value - 1) * this.perPage;
            let url;
            let role = sessionStorage.getItem("role");
            if (role == 2) {
                url =
                    "/v2/clearing/admin/abx/get/customer/transaction?startDate=" +
                    startDate +
                    "&endDate=" +
                    endDate +
                    "&offset=" +
                    offset +
                    "&limit=" +
                    this.perPage +
                    "&code=" +
                    clientType +
                    "&bank_code=" +
                    bankCode +
                    "&order_match_id=" +
                    order_id;
            } else if (role == 6) {
                url =
                    "/v2/clearing/admin/pos/get/customer/transaction?startDate=" +
                    startDate +
                    "&endDate=" +
                    endDate +
                    "&offset=" +
                    offset +
                    "&limit=" +
                    this.perPage +
                    "&code=" +
                    clientType +
                    "&bank_code=" +
                    bankCode +
                    "&order_match_id=" +
                    order_id;
            } else if (role == 4) {
                url =
                    "/v2/clearing/admin/bank/get/customer/transaction?startDate=" +
                    startDate +
                    "&endDate=" +
                    endDate +
                    "&offset=" +
                    offset +
                    "&limit=" +
                    this.perPage +
                    "&code=" +
                    clientType +
                    "&bank_code=" +
                    bankCode +
                    "&order_match_id=" +
                    order_id;
            } else {
                url =
                    "/v2/clearing/admin/get/customer/transaction?limit=" +
                    this.perPage +
                    "&code=" +
                    clientType +
                    "&bank_code=" +
                    bankCode +
                    "&startDate=" +
                    startDate +
                    "&endDate=" +
                    endDate +
                    "&offset=" +
                    offset +
                    "&order_match_id=" +
                    order_id;
            }

            this.loadingData = true;
            axios
                .get(url)
                .then((res) => {
                    this.loadingData = false;
                    this.data = res.data.data.body;
                    this.lengthData = res.data.data.count;
                    for (let i = 0; i < this.data.length; i++) {
                        let trade;
                        let tradeSell;
                        let buy = {};
                        let sell = {};

                        if (this.data[i].direction.length < 2) {
                            if (this.data[i].direction[0].direction == "sell") {
                                tradeSell = this.data[i].direction[0].trade_id;
                                this.data[i].direction[1] = this.data[i].direction[0];
                                this.data[i].direction[1].tradeSell = tradeSell.split(";");
                                this.data[i].direction[0] = {
                                    date_pay: "-",
                                    direction: "-",
                                    order_id: "-",
                                    order_match_id: "-",
                                    total_amount_idr: 0,
                                    trade_id: "-",
                                    tradebuy: ["-", "-", "-"],
                                    va_code: "-",
                                    _id: "-",
                                };
                            } else {
                                trade = this.data[i].direction[0].trade_id;
                                this.data[i].direction[0].tradebuy = trade.split(";");
                                this.data[i].direction[1] = "";
                            }
                        } else {
                            if (this.data[i].direction[0].direction == "sell") {
                                buy = this.data[i].direction[1];
                                sell = this.data[i].direction[0];
                            } else {
                                buy = this.data[i].direction[0];
                                sell = this.data[i].direction[1];
                            }

                            this.data[i].direction[1] = sell;
                            tradeSell = this.data[i].direction[1].trade_id;
                            this.data[i].direction[1].tradeSell = tradeSell.split(";");
                            this.data[i].direction[0] = buy;
                            trade = this.data[i].direction[0].trade_id;
                            this.data[i].direction[0].tradebuy = trade.split(";");
                            this.data[i].type_client = trade.slice(0, 2) + "-" + tradeSell.slice(0, 2);
                            this.data[i].lpbuy = trade.slice(0, 2);
                            this.data[i].lpsell = tradeSell.slice(0, 2);
                        }
                    }
                })
                .catch((error) => {
                    this.loadingData = false;
                    console.log(error);
                });
        },
    },
    computed: {
        rows() {
            return (this.currentPage - 1) * this.perPage;
        },
        filterDatas() {
            return this.dataExcel;
        },
        isLoadingExcel() {
            return this.isLoading;
        },
        // maxTodayDownload() {
        //     let maxDateEndate = "";
        //     let today = new Date().toISOString().split("T")[0];
        //     if (this.formExcel.startDate.slice(0, 7) === today.slice(0, 7)) {
        //         maxDateEndate = today;
        //     } else {
        //         const date = new Date(this.formExcel.startDate);
        //         const month = date.getMonth();
        //         const year = date.getFullYear();
        //         const maxDate = new Date(year, month + 1, 0).getDate();
        //         maxDateEndate = this.formExcel.startDate.slice(0, 7) + "-" + JSON.stringify(maxDate);
        //     }
        //     return maxDateEndate;
        // },
    },
};
</script>

<style scoped>
.top-title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.bagi {
    height: 30px;
}

.top-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
    background: #fff;
    box-shadow: 0px 5px 16px 1px rgb(0 0 0 / 15%);
    padding: 25px 20px;
    border-radius: 5px;
    position: relative;
}

.title-page {
    font-size: 26px;
    font-weight: bold;
}

.title-page div {
    font-size: 18px;
    font-weight: normal;
    margin-top: -20px;
}

.icon-trade {
    width: 25px;
}

table {
    display: block;
    overflow-x: auto;
}

.footer {
    background: var(--primary-color);
    color: white;
    padding: 20px 47px;
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    height: 62px;
}

.form-input {
    border: solid 1px #ccc;
    height: 31px;
    padding: 5px 10px;
    border-radius: 5px;
    color: #aaa;
    font-size: 12px;
    width: 100%;
}

.filter-trade::placeholder {
    color: var(--scondary-color);
}

.filter {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-size: 12px;
    flex-wrap: wrap;
    position: relative;
    flex: 1;
}

.wrap-button {
    display: flex;
    justify-content: end;
    align-items: center;
}

.card {
    width: 314px;
    font-size: 14px;
    border-radius: 10px;
}

.card-header {
    padding: 1rem;
}

.buyer,
.seller,
.card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.title {
    font-size: 12px;
    position: relative;
    left: 20px;
}

.title::before {
    content: "";
    position: absolute;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: var(--scondary-color);
    left: -17px;
    top: 3px;
}

.buyer {
    margin-bottom: 20px;
}

.content-right {
    text-align: right;
}

.amount {
    font-weight: bold;
}

.btn {
    font-size: 13px;
    padding: 5px 15px;
    margin-right: 0.5rem;
}

.btn-primer {
    background: var(--scondary-color);
    border: none;
    color: #ffffff;
    padding: 5px 20px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-primer:hover,
.btn-filter:hover {
    box-shadow: 0 7px 14px rgb(50 50 93 / 10%), 0 3px 6px rgb(0 0 0 / 8%);
    transform: translateY(-1px);
}

.btn-filter {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px 20px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.reset-filter button {
    border: none;
    color: #fff;
    background: #f75676;
    padding: 5px 10px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    cursor: pointer;
}

.btn-filter:focus {
    outline: 1px solid;
}

.description {
    text-align: left !important;
}

.sort-date {
    position: relative;
}

button[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
}

.filter-select {
    border: none;
    background: #fff;
    color: var(--scondary-color);
    padding: 5px;
    font-size: 13px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.wrap-dropdown {
    position: relative;
}

.filter-dropdown {
    padding: 1rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.dropdown {
    position: absolute;
    left: 0;
    top: 2.5rem;
    background: #fff;
    padding: 1rem;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
}

.dropdown-excel {
    background: #fff;
    padding: 10px 20px;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
    border-radius: 5px;
    right: 18px;
    position: absolute;
    top: 4rem;
}

.excel {
    top: 58px;
}

input:disabled {
    cursor: not-allowed;
}

.view-perpages select {
    border-radius: 3px;
    border-color: #a5a5a5a5;
    padding: 3px;
}
</style>
